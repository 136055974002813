import React, { useEffect, useState } from 'react';

import { Layout, SEO } from '../components';
import Logo from '../assets/images/logo-small.svg';
import { useSelector } from 'react-redux';

const Account = ({ location }) => {
  const user = useSelector((state) => state.user.data);

  return (
    <Layout>
      <SEO title="Account" />
      <article className="content-section">
        <div className="container">
          <div className="w-layout-grid grid">
            <div className="content-block">
              <p>{user ? "logged-in" : "logged-out" }</p>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Account;
